import React from "react"
import { Link, PageProps, graphql } from "gatsby"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import Challenge from "@/components/Challenge"
import Countdown from "@/components/Countdown"
import { routes } from "@/utils/routes"
import Raised from "@/components/Pledge/Raised"

export const pageQuery = graphql`
  query HomepageQuery {
    sanityPage(title: {eq: "Homepage"}) {
      amountRaised
    }
    allSanityVideo(sort: {fields: position}) {
      edges {
        node {
          title
          position
          videoUrl
          description
          placeholder {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const Home: React.FC<PageProps> = ({ data }: { data: any }) => {
  // TODO: console.log({ amountRaised: data.sanityPage.amountRaised })
  function getVideoLink(position: number) {
    if (position === 2) {
      return routes.schoolProjects()
    } else if (position === 3) {
      return routes.socialMobility()
    } else {
      return routes.video({ params: { slug: `video-${position}` } })
    }
  }

  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_banner_2024.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_banner_2024.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <div className="pb-2 md:pb-12">
                <h1 className="responsive-heading">
                  EQUIPPING <span className="text-primary">OUR PUPILS</span> <br className="hidden lg:block" />TO <span className="text-primary">EXCEL</span>
                </h1>
              </div>

              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title="Home" />

      <div className="container py-14">
        <div className="flex justify-center">
          <div className="max-w-[700px]">
            <h3 className="mb-4 text-3xl uppercase">
              Working with alumni, parents, and the wider <span className="text-primary">Instonian community</span>, the RBAI Foundation raises funds to support the <span className="text-primary">development of the School</span>.
            </h3>
            <div className="md:text-xl">
              <p className="mb-4"> Alumni also play an active role in supporting pupils through careers talks, mentoring, coaching and providing work experience.</p>
              <p className="mb-4">
                The RBAI Foundation acts as a hub for communication with 8,000 Instonians around the world, hosting alumni events across the UK and overseas, as well as promoting the Belfast Old Instonians Association (BOIA) annual dinners in Belfast, Edinburgh and London.
              </p>
            </div>

            <a href="/docs/RBAI-donor-report-JUN24-FINAL.pdf" target="_blank" className="inline-flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              <div>
                <strong>Read our latest Impact Report here</strong>
              </div>
            </a>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
